'use client';

import React, { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import BackIcon from 'public/assets/icons/back.svg';
import Button from '@/components/ui/Button';
import LogoutIcon from 'public/assets/icons/logout-icon.svg';
import { AuthService } from '@/service/AuthService';
import { LOGIN_PAGE, WALLET_PAGE } from '@/constants/routes.const';
import OfferPayout from '../OfferPayout';
import { StorageService } from '@/service/StorageService';
import { cn } from '@/utils';
import openConfirmAlert from '../ConfirmAlert';
import Link from 'next/link';
import useAuthContext from '@/context/authContext';
import { LANGUAGES_CONTENT } from '@/constants/language';

export default function NavbarWithBack({
  backText = null,
  showLogoutBtn = false,
  showWallet = false,
  classes,
}) {
  const router = useRouter();
  let cleanUpConfirmAlert;

  const goPageBack = () => {
    router.back();
  };

  const { walletBalance } = useAuthContext();

  // const {
  //   call: getWalletBalance,
  //   data: walletBalance,
  //   isLoaded: isBalanceLoaded,
  // } = useService(WalletService.getWalletBalance);

  useEffect(() => {
    if (!StorageService.getAccessToken()) return;
    // if (showWallet) {
    //   getWalletBalance(StorageService.getWId());
    // }
    return () => {
      cleanUpConfirmAlert?.();
    };
  }, []);

  const CONTENT = LANGUAGES_CONTENT[StorageService.getLanguage()]['nav'];

  return (
    <div
      className={cn('sticky top-0 inset-x-0 z-[10] bg-white', classes?.wrapper)}
    >
      <div
        className={cn(
          'relative w-full flex items-center gap-2 p-2 justify-between',
          classes?.container
        )}
      >
        <div
          className="flex items-center justify-start w-full gap-2"
          onClick={goPageBack}
        >
          <BackIcon className="text-base" />
          <p className="text-lg">{backText || CONTENT.back}</p>
        </div>
        {showLogoutBtn && (
          <Button
            className="flex items-center gap-2 bg-[var(--violet-4)] text-accent-color"
            variant="soft"
            size={'3'}
            onClick={() => {
              let { handleCleanup } = openConfirmAlert({
                title: CONTENT.logout,
                message: CONTENT.logoutmodal,
                onOk: () => {
                  AuthService.logout();
                  router.replace(LOGIN_PAGE);
                },
              });
              cleanUpConfirmAlert = handleCleanup;
            }}
          >
            <LogoutIcon className="text-accent-color" /> {CONTENT.logout}
          </Button>
        )}
        {showWallet && (
          <Link href={WALLET_PAGE} className="grid w-full rounded">
            <OfferPayout
              amount={walletBalance || 0}
              size="medium"
              classes={{ text: 'font-bold', root: 'justify-end' }}
            />
            <div className="text-xs text-right text-accent-color">
              {CONTENT.wallet}
            </div>
          </Link>
        )}
      </div>
    </div>
  );
}
